import React, { useState } from "react";
import axios from "axios";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container as ContainerBase } from "components/misc/Layouts";
import tw from "twin.macro";
import "../components/hero/style.css";
import styled from "styled-components";
// import { css } from "styled-components/macro"; //eslint-disable-line
import illustration from "images/signup-illustration.svg";
// import logo from "images/logo.svg";
// import googleIconImageSrc from "images/google-icon.png";
// import twitterIconImageSrc from "images/twitter-icon.png";
// import { ReactComponent as SignUpIcon } from "feather-icons/dist/icons/user-plus.svg";

const Container = tw(
  ContainerBase
)`min-h-screen bg-primary-900 text-white font-medium flex justify-center -m-8`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
// const LogoLink = tw.a``;
// const LogoImage = tw.img`h-12 mx-auto`;
// mt - 12;
const MainContent = tw.div` flex flex-col items-start text-left`;
const Heading = tw.h1`text-2xl xl:text-3xl mb-4 font-extrabold`;
const FormContainer = tw.div`w-full flex-1 mt-8`;
const ComingContentQuestion = tw.h2`xl:mt-3  text-lg xl:text-xl font-bold`;
const ComingContent = tw.h4`text-base xl:text-base mt-2 text-gray-600`;
// text-lg xl:text-lg font-semibold leading-normal mb-8 p-1

// const SocialButtonsContainer = tw.div`flex flex-col items-center`;
// const SocialButton = styled.a`
//   ${tw`w-full max-w-xs font-semibold rounded-lg py-3 border text-gray-900 bg-gray-100 hocus:bg-gray-200 hocus:border-gray-400 flex items-center justify-center transition-all duration-300 focus:outline-none focus:shadow-outline text-sm mt-5 first:mt-0`}
//   .iconContainer {
//     ${tw`bg-white p-2 rounded-full`}
//   }
//   .icon {
//     ${tw`w-4`}
//   }
//   .text {
//     ${tw`ml-4`}
//   }
// `;

// const DividerTextContainer = tw.div`my-12 border-b text-center relative`;
// const DividerText = tw.div`leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium bg-white transform -translate-y-1/2 absolute inset-x-0 top-1/2 bg-transparent`;

const Form = tw.form`mx-auto max-w-xs`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border-2 border-gray-400 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;
const IllustrationContainer = tw.div`sm:rounded-r-lg flex-1 bg-purple-100 text-center hidden lg:flex justify-center`;
const IllustrationImage = styled.div`
  ${(props) => `background-image: url("${props.imageSrc}");`}
  ${tw`m-12 xl:m-16 w-full max-w-lg bg-contain bg-center bg-no-repeat`}
`;

export default ({
  // logoLinkUrl = "#",
  illustrationImageSrc = illustration,
  headingText = "Coming Soon",
  comingcontent1 = "Dynamic Image rendering in your website.",
  comingcontent2_1 = "Create Simple image transitions",
  comingcontent2_2 = "Show images based on Location of Visitor",
  comingcontent2_3 = "Update, modify images without any code change in your website",
  comingcontent3 = "Upload images, Choose animation, Copy code and paste in your website",
  // socialButtons = [
  //   {
  //     iconImageSrc: googleIconImageSrc,
  //     text: "Sign Up With Google",
  //     url: "https://google.com",
  //   },
  //   {
  //     iconImageSrc: twitterIconImageSrc,
  //     text: "Sign Up With Twitter",
  //     url: "https://twitter.com",
  //   },
  // ],
  submitButtonText = "Notify Me",
  // SubmitButtonIcon = SignUpIcon,
  // tosUrl = "#",
  // privacyPolicyUrl = "#",
  // signInUrl = "#",
}) => {
  const [email, handleEmail] = useState("");
  const [isValid, handleValid] = useState(null);
  const [emptymail, entermail] = useState(null);
  const [isTrue, applyShadow] = useState(false);

  const handleChangeEmail = (e) => {
    // console.log("event email",e)
    handleEmail(e);
    emailValidation();
    applyShadow(false);
    // console.log('email: ', email)
    if (email !== "" && email !== null) {
      return entermail(true);
    }
  };

  const handleInviteEmail = async (e) => {
    e.preventDefault();
    // console.log("emaillll",email)
    // mailerSender(email);
    try {
      let obj = { emailId: email };
      // console.log("isValid",isValid)
      if (email === "" || email === null) {
        // console.log("🚀 ~ file: TwoColumnWithInput.js ~ line 74 ~ handleInviteEmail ~ entermail",emptymail )
        return entermail(false);
      } else if (isValid === true) {
        entermail(true);
        // console.log("🚀 ~ file: TwoColumnWithInput.js ~ line 71 ~ handleInviteEmail ~ validCheck")
        axios.post("https://up.rishany.com:7443/sendmailnewprod", obj);
        await handleEmail("");
        await applyShadow(true);
        // console.log(response);
        return false;
      } else {
        return entermail(true);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const emailValidation = () => {
    let expression = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    let valid = expression.test(email);
    handleValid(valid);
  };
  return (
    <AnimationRevealPage>
      <Container className="greenColor">
        <Content>
          <MainContainer>
            {/* <LogoLink href={logoLinkUrl}>
            <LogoImage src={logo} />
          </LogoLink> */}
            <Heading>{headingText}</Heading>

            <MainContent>
              {/* <Heading>{headingText}</Heading> */}
              <ComingContentQuestion>What is this ?</ComingContentQuestion>
              <ComingContent>{comingcontent1}</ComingContent>
              <ComingContentQuestion>What can be done ?</ComingContentQuestion>
              <ComingContent>{comingcontent2_1}</ComingContent>
              <ComingContent>{comingcontent2_2}</ComingContent>
              <ComingContent>{comingcontent2_3}</ComingContent>
              <ComingContentQuestion>How ?</ComingContentQuestion>
              <ComingContent>{comingcontent3}</ComingContent>
              <FormContainer>
                <Form>
                  <Input
                    type="email"
                    placeholder="Email"
                    value={email}
                    className={isTrue && "button-glow"}
                    style={{
                      borderColor:
                        emptymail === false || email === ""
                          ? "#e2e8f0"
                          : isValid === false || emptymail === false
                          ? "#f00808"
                          : (isValid === true || emptymail === true) &&
                            "#1ae21a",
                    }}
                    onChange={(e) => handleChangeEmail(e.target.value)}
                  />
                  <SubmitButton
                    onClick={handleInviteEmail}
                    type="submit"
                    className="greenColor"
                  >
                    <span className="text notify_text ">
                      {submitButtonText}
                    </span>
                  </SubmitButton>
                </Form>
              </FormContainer>
            </MainContent>
          </MainContainer>
          <IllustrationContainer>
            <IllustrationImage imageSrc={illustrationImageSrc} />
          </IllustrationContainer>
        </Content>
      </Container>
    </AnimationRevealPage>
  );
};
