import React,{useState} from "react";
import tw from "twin.macro";
import emailjs from 'emailjs-com'

const ModelStartButton = tw.button`bg-pink-500 text-white active:bg-pink-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150`
const Model = tw.div`justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none`
const ModelBackground = tw.div`opacity-25 fixed inset-0 z-40 bg-black`
const ModelMain = tw.div`relative w-auto my-6 mx-auto max-w-sm`
const ModelContent = tw.div`border-t-4  rounded shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none`
const ModelHeader = tw.div`flex items-start justify-between px-4 py-2 border-solid rounded-t`
const ModelBody = tw.div`relative px-5 flex-auto`
const ModelAction = tw.div`relative px-4 py-4 flex-auto`
const ModelClose = tw.button`outline-none`
const Modelh3 = tw.h3`text-3xl font-semibold`
const ModelCloseIcon = tw.span`bg-transparent text-black text-red-500 font-semibold h-6 w-6 text-2xl block outline-none focus:outline-none`
const ModelCloseButton = tw.button`text-red-500  font-bold uppercase px-4 py-1 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150`
const ModelBodyContent = tw.p`my-3 text-base leading-relaxed`
const Input  = tw.input`appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`
export default function NoticeModal({showModal,setShowModal}) {
    const initialData = {email:""}
    const [data,setData] = useState(initialData)
    let regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const onChange = (e) => {
        const {name,value} = e.target
        setData({[name]:value})
    }
    const sendMail = () => {
        if(regex.test(data.email)){
            const templateParams = {
                message:data.email,
            }
            emailjs.send('service_dc4d0nd', 'template_yge755j',templateParams,"user_aLU0a5lpdOv1NkfyJEw2q")
                .then(function(response) {
                   console.log('SUCCESS!', response.status, response.text);
                }, function(error) {
                   console.log('FAILED...', error);
            })
            setData(initialData)
            setShowModal(false)
        }
    }

  return (
    <>
      {showModal ? (
        <>
          <Model>
            <ModelMain>
              <ModelContent style={{borderBlockColor:'rgb(244, 126, 48)'}}>
                <ModelHeader>
                  <Modelh3>
                    Notice
                  </Modelh3>
                  <ModelClose
                    onClick={() => setShowModal(false)}
                    style={{outline:'none'}}
                  >
                    <ModelCloseIcon>
                      ×
                    </ModelCloseIcon>
                  </ModelClose>
                </ModelHeader>
                <ModelBody>
                  <ModelBodyContent>
                  Thank you for taking  time to check out Scouty. We have got some fantastic feedback, enhancement requests on Scouty from beta Users. We are working on an upgrade that will incorporate all those suggestions and requirements. Once we have it ready, We will reach out to you with a re-launched Scouty.
                  </ModelBodyContent>
                  <ModelBodyContent>
                  Type your Email Address below and click on Notify Me, so we keep you informed of our progress.
                  </ModelBodyContent>
                  <Input id="grid-first-name" name="email" onChange={onChange} value={data.email} type="email" placeholder="Your E-mail Address"></Input>
                </ModelBody>
                <ModelAction>
                  <ModelCloseButton
                    type="button"
                    onClick={() => sendMail()}
                  >
                    Notify Me
                  </ModelCloseButton>
                </ModelAction>
              </ModelContent>
            </ModelMain>
          </Model>
          <ModelBackground></ModelBackground>
        </>
      ) : null}
    </>
  );
}
