import React , {useState} from "react";
import styled from "styled-components";
import tw from "twin.macro";
// import {mailerSender} from "./../../email/email";
import axios from 'axios';
import './style.css'
//eslint-disable-next-line
import { css } from "styled-components/macro";

import Header from "../headers/light.js";

import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";
import DesignIllustration from "../../images/design-illustration-2.svg";
import CustomersLogoStripImage from "../../images/customers-logo-strip.png";
import Wave from 'react-wavify';
import TextScramble from "components/extras/textscramble.js";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col lg:flex-row lg:items-center max-w-screen-xl mx-auto py-20 md:py-24`;
const LeftColumn = tw.div`relative lg:w-5/12 text-center max-w-lg mx-auto lg:max-w-none lg:text-left`;
const RightColumn = tw.div`relative mt-12 lg:mt-0 flex-1 flex flex-col justify-center lg:self-end`;

const Heading = tw.h1`font-bold text-3xl md:text-3xl lg:text-4xl xl:text-5xl text-gray-900 leading-tight`;
const Paragraph = tw.p`my-5 lg:my-8 text-base xl:text-lg`;

const Actions = styled.div`
  ${tw`relative max-w-md text-center mx-auto lg:mx-0`}
  input {
    ${tw`sm:pr-48 pl-8 py-4 sm:py-5 rounded-full border-2 w-full font-medium focus:outline-none transition duration-300  focus:border-primary-500 hover:border-gray-500`}
  }
  button {
    ${tw`w-full sm:absolute right-0 top-0 bottom-0 bg-primary-500 text-gray-100 font-bold mr-2 my-4 sm:my-2 rounded-full py-4 flex items-center justify-center sm:w-40 sm:leading-none focus:outline-none hover:bg-primary-900 transition duration-300`}
  }
`;

const IllustrationContainer = tw.div`flex justify-center lg:justify-end items-center`;

// Random Decorator Blobs (shapes that you see in background)
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3 -z-10`}
`;

const CustomersLogoStrip = styled.div`
  ${tw`mt-12 lg:mt-20`}
  p {
    ${tw`uppercase text-sm lg:text-xs tracking-wider font-bold text-gray-500`}
  }
  img {
    ${tw`mt-4 w-full lg:pr-16 xl:pr-32 opacity-50`}
  }
`;

export default ({ roundedHeaderButton,openNotice }) => {
  const [email,handleEmail] = useState(null);
  const [isValid,handleValid] = useState(null);
  const [emptymail,entermail] = useState(null);
  const [isTrue,applyShadow] = useState(false);

  const handleChangeEmail=(e)=>{
  // console.log("event email",e)
  handleEmail(e);
  emailValidation();
  applyShadow(false);
  // console.log('email: ', email)
  if( email!='' && email != null){
     return entermail(true);
    }
  }

  const handleInviteEmail= async () =>{
    // console.log("emaillll",email)
    // mailerSender(email);
      try {
        let obj={emailId: email}
        // console.log("isValid",isValid)
        if( email=='' || email == null){
        // console.log("🚀 ~ file: TwoColumnWithInput.js ~ line 74 ~ handleInviteEmail ~ entermail",emptymail )
         return entermail(false);
        }
        else if(isValid == true){
        entermail(true);
        // console.log("🚀 ~ file: TwoColumnWithInput.js ~ line 71 ~ handleInviteEmail ~ validCheck")
        axios.post('https://up.rishany.com:7443/sendmailweb',obj)
        await handleEmail("");
        await applyShadow(true);
        // console.log(response);
        return false;
        }
        else{
          return entermail(true);;
        }
        
      } catch (error) {
        console.error(error);
      }
    }
    const emailValidation =()=> {
       let expression=   /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
       let valid=expression.test(email);
       handleValid(valid);
    }

  return (
    <>
      <Header openNotice={openNotice} roundedHeaderButton={roundedHeaderButton} />
      <Container>
        <TwoColumn id='hello' style={{paddingTop:'2.5rem'}}>
          <LeftColumn>
            <Heading>
            Scout information that's business <span tw="text-primary-500" style={{color:'#f47e30'}}>for you.</span>
            </Heading>
            <Paragraph>
            Wouldn't it be great if you could proactively participate in online platforms and forums where they discuss about the problems or requirements your business solve ?
            </Paragraph>
            <Paragraph style={{color:'#f47e30',fontWeight: 500}}>Try Scouty to identify and connect to prospects.</Paragraph>
            <Actions>
              <input id="emailInput" type="text" placeholder="Your E-mail Address" value={email} className={isTrue && "button-glow"} style={{borderColor: (emptymail == false ||email == "")?'#e2e8f0':(isValid == false || emptymail == false)?'#f00808':(isValid == true || emptymail == true)  && '#1ae21a'}} onChange={(e)=>handleChangeEmail(e.target.value)}/>
              <button style={{backgroundColor:'#f47e30'}} onClick={handleInviteEmail}>Get Started</button>
            </Actions>
            {/* <CustomersLogoStrip>
              <p>Our TRUSTED Customers</p>
              <img src={CustomersLogoStripImage} alt="Our Customers" />
            </CustomersLogoStrip> */}
          </LeftColumn>
          <RightColumn>
           <IllustrationContainer>
           {/* <Text/> */}
           <TextScramble/>
            {/* <img tw="min-w-0 w-full max-w-lg xl:max-w-3xl" src={DesignIllustration} alt="Design Illustration" /> */}
            </IllustrationContainer>
          </RightColumn>
        </TwoColumn>
        
        <Wave fill='#f79902'
        paused={false}
        style={{pointerEvents: 'none', opacity: '0.15' , position: 'absolute', left: '-35px',bottom: '0', height: '7rem', width: '100rem'}}
        options={{
          height: 10,
          amplitude: 20,
          speed: 0.10,
          points: 6
        }}
  />
  <DecoratorBlob1 />
      </Container>
    </>
  );
};


